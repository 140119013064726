import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
const initialState = {
    status: 'idle',
    models: [],
};
// ----------------------------------------------------------------------
export const getAllModels = createAsyncThunk('model/getAllModels', async (id) => {
    let data;
    try {
        const response = await axios.get(`/models?search=brand.id:${Number(id)}&isPaging=false`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'model',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllModels.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getAllModels.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.models = action.payload;
        })
            .addCase(getAllModels.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice;
